@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.ColipaysCustomer_Pickup_Result
  table
    margin-top 30px

.ColipaysCustomer_Pickup_Loyalty_Subscribe
  margin-top 30px