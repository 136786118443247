@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.EtatSaisieRapide

  .pages
    margin-top 10px

.EtatSaisieRapide__Download
  margin-bottom 20px