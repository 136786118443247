@import '_mixins'

//
// ---
//


.text-center
  text-align center

.hidden
  display none

.top-actions
  margin-bottom 20px

.panel-body

  .alert
    margin 20px auto

    &:first-child
      margin-top 0

    &:last-child
      margin-bottom 0

#nprogress .bar
  z-index 9999
  background $red
  box-shadow 0 0 10px $red,0  0 5px $red;

th.number, td.number
  text-align right

.datepicker
  z-index 10000 !important