@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.ColikadoParcel_Actions
  /*
   * ----------------------------------------------------------------------------
   */
.ColikadoParcel_Actions
  margin-bottom 20px

  .AppForm & .grid__row .grid__col
      flex-basis auto
      max-width initial

/*
 * ----------------------------------------------------------------------------
 */

.ColikadoParcel_Actions__Action

  &, &:hover, &:active, &visited
    padding 10px 37px
    text-align center
    cursor pointer

  &[disabled]
    cursor not-allowed

  .ico
    display block
    font-size 96px