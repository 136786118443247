@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.ColikadoParcel_Address_Address

  .form-control
    margin-top: 10px;

    &:nth-child(1)
      margin-top

.ColikadoParcel__Address--view

  label
    display block
    font-weight 400