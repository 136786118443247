@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.SaisieFax__SousTitre
  @extend $form-section-title

/*
 * ----------------------------------------------------------------------------
 */

.SaisieFax__ExistingForm_Reset--top
  margin-bottom $formPadding

/*
 * ----------------------------------------------------------------------------
 */

.SaisieFax__ExistingForm__Data

  .AppView__Address_Address
    max-width 410px
    width 100%
