/*
 * ----------------------------------------------------------------------------
 */
.ColikadoParcel_Address_Address .form-control {
  margin-top: 10px;
}
.ColikadoParcel__Address--view label {
  display: block;
  font-weight: 400;
}
