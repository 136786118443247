.AppForm__Section_Titre {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
}
.AppForm__Section_Titre .ico {
  margin-right: 10px;
  color: #d6494b;
}
/*
 * FORM GENERIC RESPONSIVE LAYOUT
 */
.AppForm {
  max-width: 1280px;
}
.AppForm label.required:after,
.AppForm .field-required label:after {
  content: "(obligatoire)";
  display: inline-block;
  padding-left: 5px;
  color: #d6494b;
  font-size: 80%;
  opacity: 0.6;
}
.AppForm.form-horizontal label.required:after,
.AppForm.form-horizontal .field-required label:after {
  position: absolute;
  display: block;
  right: 0;
  margin-right: 15px;
}
.AppForm .as-input {
  display: block;
}
.AppForm .hint-text {
  font-size: 90%;
}
/* FORM STACKED */
.AppForm--stacked label {
  display: block;
  padding-right: 0;
}
/* FORM BIGGER SIZE */
.AppForm--big input[type=text],
.AppForm--big input[type=number],
.AppForm--big input[type=email],
.AppForm--big input[type=password] {
  padding: 8px 15px;
  font-size: 120%;
}
.AppForm--big label {
  font-size: 115%;
}
/*
 * FORM CONTENT ROW
 */
.AppForm_Content,
.AppForm_Buttons {
  margin: 10px 0;
}
.AppForm_Buttons {
  margin: 20px 0 0;
  padding: 0.5rem 0;
  border-top: 1px solid #0459b7;
  background-color: #dfeefe;
}
