.ListingDepartPalette .palette0 {
  background-color: #fff !important;
}
.ListingDepartPalette .palette1 {
  background-color: #f4efec !important;
}
.ListingDepartPalette .palette2 {
  background-color: #f4e0e9 !important;
}
.ListingDepartPalette .palette3 {
  background-color: #f4d9d0 !important;
}
.ListingDepartPalette .palette4 {
  background-color: #f4e3c9 !important;
}
.ListingDepartPalette .palette5 {
  background-color: #b5dce1 !important;
}
.ListingDepartPalette .palette6 {
  background-color: #d7e0b1 !important;
}
.ListingDepartPalette .palette7 {
  background-color: #d6cdc8 !important;
}
.ListingDepartPalette .palette8 {
  background-color: #cfdaf0 !important;
}
.ListingDepartPalette .palette9 {
  background-color: #f6eeea !important;
}
.ListingDepartPalette .palette10 {
  background-color: #f9dbe8 !important;
}
.ListingDepartPalette .palette11 {
  background-color: #fdd4c7 !important;
}
.ListingDepartPalette .palette12 {
  background-color: #ffe5be !important;
}
.ListingDepartPalette .palette13 {
  background-color: #aae5ec !important;
}
.ListingDepartPalette .palette14 {
  background-color: #deeca5 !important;
}
.ListingDepartPalette .palette15 {
  background-color: #daccc5 !important;
}
.ListingDepartPalette .palette16 {
  background-color: #c7d7f8 !important;
}
