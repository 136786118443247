@import '../_mixins'

.CaisseActiveListing

  .CaisseActiveListing__Listing
    padding-top 20px
    padding-bottom 100px

    .CaisseActiveListing__Listing_Header--bordereau
      min-width 105px

    .CaisseActiveListing__Listing_Header--produit
      min-width 115px

    .CaisseActiveListing__Listing_Header--prix
      min-width 85px

    tr.encours td
      color red

    .btn-group li a
      text-align left