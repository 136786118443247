@font-face {
    font-family: "app-icon";
    src: url('fonts/app-icon.eot');
    src: url('fonts/app-icon.eot?#iefix') format('eot'),
    url('fonts/app-icon.woff') format('woff'),
    url('fonts/app-icon.ttf') format('truetype'),
    url('fonts/app-icon.svg#app-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ico {
    line-height: 1;
}

.ico:before {
    display: inline-block;
    font-family: "app-icon";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ico-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.ico-2x { font-size: 2em; }
.ico-3x { font-size: 3em; }
.ico-4x { font-size: 4em; }
.ico-5x { font-size: 5em; }
.ico-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.ico-landing:before { content: "\EA01" }
.ico-takeoff:before { content: "\EA02" }
.ico-parcel:before { content: "\EA03" }
.ico-keyboard:before { content: "\EA04" }
.ico-documents:before { content: "\EA05" }
.ico-printer:before { content: "\EA06" }
.ico-process:before { content: "\EA07" }
.ico-customs:before { content: "\EA08" }
.ico-sales:before { content: "\EA09" }
.ico-loading-zone:before { content: "\EA0A" }
.ico-limits:before { content: "\EA0B" }
.ico-user:before { content: "\EA0C" }