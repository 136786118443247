.CaisseActiveListing .CaisseActiveListing__Listing {
  padding-top: 20px;
  padding-bottom: 100px;
}
.CaisseActiveListing .CaisseActiveListing__Listing .CaisseActiveListing__Listing_Header--bordereau {
  min-width: 105px;
}
.CaisseActiveListing .CaisseActiveListing__Listing .CaisseActiveListing__Listing_Header--produit {
  min-width: 115px;
}
.CaisseActiveListing .CaisseActiveListing__Listing .CaisseActiveListing__Listing_Header--prix {
  min-width: 85px;
}
.CaisseActiveListing .CaisseActiveListing__Listing tr.encours td {
  color: #f00;
}
.CaisseActiveListing .CaisseActiveListing__Listing .btn-group li a {
  text-align: left;
}
