.sweet-alert button {
  margin-bottom: 1em;
}
.sweet-alert h2 {
  border-bottom: none;
  letter-spacing: normal;
  font-size: 3rem;
  line-height: 3rem;
}
.sweet-alert>p {
  margin: 2em auto;
}
.sweet-alert ul,
.sweet-alert ol,
.sweet-alert li {
  float: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sweet-alert code {
  padding: 10px;
  display: block;
  border: 1px solid #d4d4d4;
  background: #f3f3f3;
  margin-top: 10px;
  font-size: 11px;
  font-family: monospace;
  word-wrap: break-word;
}
.sweet-alert button.confirm[disabled] {
  white-space: nowrap;
  overflow: hidden;
  text-indent: 2000px;
}
