@import '../_mixins'

.AttributionPalette__Limites__Container
  margin-bottom 15px

.AttributionPalette__Listing
.AttributionPalette__Limites
  margin-bottom 0

.AttributionPalette__Limites
  font-size 0.9em

  th
    font-size 0.9em

  .limit--total, .limit--category
    font-weight bold
  
  .limit--number, .limit--type
    text-align right

  .palet--current
    &, & td
      background-color $yellow
      font-weight bold

  small
    display block
    color red
    text-align right
    font-size 0.95em
