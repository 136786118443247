@import '../_mixins'

.sweet-alert

  button
    margin-bottom 1em

  h2
    border-bottom none
    letter-spacing normal
    font-size 3rem
    line-height 3rem
    
  &>p
    margin 2em auto

  ul, ol, li
    float none
    list-style-type none
    margin 0
    padding 0

  code
    padding 10px
    display block
    border 1px solid $grey
    background $lightgrey
    margin-top 10px
    font-size 11px
    font-family monospace
    word-wrap break-word

  button.confirm[disabled]
    white-space nowrap
    overflow hidden
    text-indent 2000px