/*
 * ----------------------------------------------------------------------------
 */
.ColikadoParcel_Actions {
  margin-bottom: 20px;
}
.AppForm .ColikadoParcel_Actions .grid__row .grid__col {
  flex-basis: auto;
  max-width: initial;
}
/*
 * ----------------------------------------------------------------------------
 */
.ColikadoParcel_Actions__Action,
.ColikadoParcel_Actions__Action:hover,
.ColikadoParcel_Actions__Action:active,
.ColikadoParcel_Actions__Actionvisited {
  padding: 10px 37px;
  text-align: center;
  cursor: pointer;
}
.ColikadoParcel_Actions__Action[disabled] {
  cursor: not-allowed;
}
.ColikadoParcel_Actions__Action .ico {
  display: block;
  font-size: 96px;
}
