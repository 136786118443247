@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.StocksPlanner_Items
  clear both

.StocksPlanner_Items_Container
  display flex
  flex-direction row
  flex-wrap wrap
  align-content flex-start
  justify-content flex-start

.StocksPlanner_Items_Item_Container
  flex-grow 0
  align-self flex-start

.StocksPlanner_Items_Item
  border 1px solid $black
  min-width 360px
  max-width 700px
  margin 0 15px 15px 0

  .row
    margin-left 0 !important
    margin-right 0 !important
    [class*="col-"]
      padding-left 0 !important
      padding-right 0 !important

.StocksPlanner_Items_Item_Title
  padding-top 10px
  padding-bottom 10px
  font-weight bold
  text-align center
  background-color #263238
  color #ffffff

.StocksPlanner_Items_Item_Volume
  background-color $lightgrey

.StocksPlanner_Items_Item_Volume_Title
  padding-top 5px
  padding-bottom 5px
  text-align center
  line-height 22px
  vertical-align middle

.StocksPlanner_Items_Item_Volume_Value
  padding-top 5px
  padding-bottom 5px
  font-weight bold
  text-align center
  line-height 28px
  vertical-align middle

  input
    display block
    width 100%
    line-height 22px

  &--no-title
    line-height 60px
    vertical-align middle

  input
    display block
    text-align center

.StocksPlanner_Items_Item_Components
  margin-bottom 0

  thead th
    font-size 12px
    vertical-align top
    max-width 82px

  th, td
    &.--past
      background-color #fff6e9
    &.--current
      background-color #ffffff
    &.--future
      background-color #e9f3ff
    &.--stock
      font-weight bold
      max-width 68px
    &.--numeric
      text-align center
    &.--negative
      background-color #bd4e4e
      color #ffdada
    input
      display block
      width 100%
      max-width 65px
      text-align center
