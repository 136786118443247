.AttributionPalette__Limites__Container {
  margin-bottom: 15px;
}
.AttributionPalette__Listing,
.AttributionPalette__Limites {
  margin-bottom: 0;
}
.AttributionPalette__Limites {
  font-size: 0.9em;
}
.AttributionPalette__Limites th {
  font-size: 0.9em;
}
.AttributionPalette__Limites .limit--total,
.AttributionPalette__Limites .limit--category {
  font-weight: bold;
}
.AttributionPalette__Limites .limit--number,
.AttributionPalette__Limites .limit--type {
  text-align: right;
}
.AttributionPalette__Limites .palet--current,
.AttributionPalette__Limites .palet--current td {
  background-color: #ffd70b;
  font-weight: bold;
}
.AttributionPalette__Limites small {
  display: block;
  color: #f00;
  text-align: right;
  font-size: 0.95em;
}
