/*
 * ----------------------------------------------------------------------------
 */
.StocksPlanner_Items {
  clear: both;
}
.StocksPlanner_Items_Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}
.StocksPlanner_Items_Item_Container {
  flex-grow: 0;
  align-self: flex-start;
}
.StocksPlanner_Items_Item {
  border: 1px solid #000;
  min-width: 360px;
  max-width: 700px;
  margin: 0 15px 15px 0;
}
.StocksPlanner_Items_Item .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.StocksPlanner_Items_Item .row [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.StocksPlanner_Items_Item_Title {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;
  background-color: #263238;
  color: #fff;
}
.StocksPlanner_Items_Item_Volume {
  background-color: #f3f3f3;
}
.StocksPlanner_Items_Item_Volume_Title {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  line-height: 22px;
  vertical-align: middle;
}
.StocksPlanner_Items_Item_Volume_Value {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  text-align: center;
  line-height: 28px;
  vertical-align: middle;
}
.StocksPlanner_Items_Item_Volume_Value input {
  display: block;
  width: 100%;
  line-height: 22px;
}
.StocksPlanner_Items_Item_Volume_Value--no-title {
  line-height: 60px;
  vertical-align: middle;
}
.StocksPlanner_Items_Item_Volume_Value input {
  display: block;
  text-align: center;
}
.StocksPlanner_Items_Item_Components {
  margin-bottom: 0;
}
.StocksPlanner_Items_Item_Components thead th {
  font-size: 12px;
  vertical-align: top;
  max-width: 82px;
}
.StocksPlanner_Items_Item_Components th.--past,
.StocksPlanner_Items_Item_Components td.--past {
  background-color: #fff6e9;
}
.StocksPlanner_Items_Item_Components th.--current,
.StocksPlanner_Items_Item_Components td.--current {
  background-color: #fff;
}
.StocksPlanner_Items_Item_Components th.--future,
.StocksPlanner_Items_Item_Components td.--future {
  background-color: #e9f3ff;
}
.StocksPlanner_Items_Item_Components th.--stock,
.StocksPlanner_Items_Item_Components td.--stock {
  font-weight: bold;
  max-width: 68px;
}
.StocksPlanner_Items_Item_Components th.--numeric,
.StocksPlanner_Items_Item_Components td.--numeric {
  text-align: center;
}
.StocksPlanner_Items_Item_Components th.--negative,
.StocksPlanner_Items_Item_Components td.--negative {
  background-color: #bd4e4e;
  color: #ffdada;
}
.StocksPlanner_Items_Item_Components th input,
.StocksPlanner_Items_Item_Components td input {
  display: block;
  width: 100%;
  max-width: 65px;
  text-align: center;
}
