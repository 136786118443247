/*
 * ----------------------------------------------------------------------------
 */
.ColikadoParcel__Product_View label {
  display: block;
  font-weight: 400;
}
.ColikadoParcel__Product_View__Freight_Number a,
.ColikadoParcel__Product_View__Freight_Number a .as-input.form-control-static {
  color: #d6494b;
  text-decoration: underline;
}
