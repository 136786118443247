/*
 * ----------------------------------------------------------------------------
 */
.ColikadoParcel_Status__Data {
  text-align: center;
}
.ColikadoParcel_Status__Data label {
  display: block;
  font-weight: 400;
}
.ColikadoParcel_Status__Data .as-input {
  background-color: #c0ddfe;
  font-weight: 400;
}
.ColikadoParcel_Status__Data .as-input span {
  display: block;
}
