.AppContainer,
.AppContainer .table {
  color: #333;
}
.AppContainer .control-label {
  color: #666;
  font-weight: 400;
}
.AppContainer .form-control-static {
  color: #151111;
  background-color: #f9f8f8;
  padding-left: 1em;
  padding-right: 1em;
}
.AppContainer .form-control {
  color: #4d4d4d;
  background-color: #f2f2f2;
}
.AppContainer .form-control:disabled {
  color: #b5a4a4;
  background-color: #e3dcdc;
}
.AppContainer .has-error .form-control {
  background-color: #fbeded;
}
.AppContainer .has-error .form-control:disabled {
  background-color: #e3dcdc;
}
a.btn {
  text-decoration: none;
}
.btn[disabled],
.btn:disabled {
  cursor: not-allowed;
}
.hint-text {
  color: #4d4d4d;
  font-style: italic;
  font-size: 90%;
}
.AppPage .form-title {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.AppPage .page-title {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
