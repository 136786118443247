.text-center {
  text-align: center;
}
.hidden {
  display: none;
}
.top-actions {
  margin-bottom: 20px;
}
.panel-body .alert {
  margin: 20px auto;
}
.panel-body .alert:first-child {
  margin-top: 0;
}
.panel-body .alert:last-child {
  margin-bottom: 0;
}
#nprogress .bar {
  z-index: 9999;
  background: #d6494b;
  box-shadow: 0 0 10px #d6494b, 0 0 5px #d6494b;
}
th.number,
td.number {
  text-align: right;
}
.datepicker {
  z-index: 10000 !important;
}
