@import '../_mixins'

/*
 * ----------------------------------------------------------------------------
 */

.ColikadoParcel_Status__Data
  text-align center

  label
    display block
    font-weight 400

  .as-input
    background-color $lightblue
    font-weight 400

    span
      display block