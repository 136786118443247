@import "_mixins"

.AppContainer

  &, & .table
    color lighten($black, 20%)

  .control-label
    color lighten($black, 40%)
    font-weight 400

  .form-control-static
    color darken($darksalmon, 90%)
    background-color lighten($darksalmon, 90%)
    padding-left 1em
    padding-right 1em

  .form-control
    color lighten($black, 30%)
    background-color lighten($black, 95%)

    &:disabled
      color darken($darksalmon, 10%)
      background-color lighten($darksalmon, 50%)

  .has-error
    .form-control
      background-color lighten($red, 90%)

      &:disabled
        background-color lighten($darksalmon, 50%)

a.btn
  text-decoration none

.btn[disabled], .btn:disabled
  cursor not-allowed

.hint-text
  color lighten($black, 30%)
  font-style italic
  font-size 90%


.AppPage

  .form-title
    font-size 1.8rem
    font-weight 300
    text-transform uppercase
    margin-bottom 2rem

  .page-title
    font-size 3rem
    font-weight 400
    text-transform uppercase
    margin-bottom 2rem