@import '_mixins'

/*
 * FORM GENERIC RESPONSIVE LAYOUT
 */

.AppForm
  max-width 1280px

  label.required:after, .field-required label:after
    content "(obligatoire)"
    display inline-block
    padding-left 5px
    color $red
    font-size 80%
    opacity 0.6

  &.form-horizontal

    label.required:after, .field-required label:after
      position absolute
      display block
      right 0
      margin-right 15px

  .as-input
    display block

  .hint-text
    font-size 90%

/* FORM STACKED */

.AppForm--stacked

    label
      display block
      padding-right 0

/* FORM BIGGER SIZE */

.AppForm--big

  input

    &[type=text], &[type=number], &[type=email], &[type=password]
      padding 8px 15px
      font-size: 120%

  label
    font-size: 115%

/*
 * FORM CONTENT ROW
 */

.AppForm_Content, .AppForm_Buttons
  margin (2 * $formVerticalPadding) 0

//
// ---
//

.AppForm_Buttons
  margin (4 * $formVerticalPadding) 0 0
  padding: 0.5rem 0;
  border-top 1px solid $blue;
  background-color: lighten($blue, 90%);

//
// ---
//

.AppForm__Section_Titre
  @extend $form-section-title


//
// ---
//

.AppForm__InlineViewGrid
  formInlineViewGrid($formPadding)
