@import '_mixins'

//
// ---
//

.page-content

  button, input

    &[type=submit]
      button(lighten($blue, 30%), $white)

    &[type=reset]
      button($metal, $white)

  #retour
    button($metal, $white)


.table
  > thead
    > tr
      > th,
      > td
        border: 1px solid #ababab
    &:first-child
      > tr:first-child
        > th,
        > td
          border: 1px solid #ababab
  > tbody
    > tr
      > th,
      > td
        border: 1px solid #ababab
  > tfoot
    > tr
      > th,
      > td
        border: 1px solid #ababab


.table-striped > tbody > tr:nth-of-type(odd)
    background-color #f8f7f7