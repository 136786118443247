@import '_mixins.media'
@import '_mixins.colors'

/*
 * General mixins
 */
hidden-iframe()
  position absolute
  width 0
  height 0
  border-width 0
  top 0
  left 0

/* ============================================================================
 * = BUTTON MIXINS
 * ============================================================================
 */

form-section-title()
  display flex
  align-items center
  font-size 2rem
  font-weight 300
  text-transform uppercase

/* ============================================================================
 * = FORM MIXINS
 * ============================================================================
 */

$formVerticalPadding = 5px
$formPadding = 15px
$formLabelWidth = 200px

$form-section-title
  form-section-title()

  .ico
    margin-right 10px
    color $red